<template>
    <div>
        <div id='navbar' class="position-fixed w-100">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center nav-brand">
                        <svg id="Laag_1" class='nav-logo' data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 812.72 113.37"><path d="M545.11,381.93a31.52,31.52,0,0,1,23.25-9.64h91.06V485.66h-26V398.18h-65a6.9,6.9,0,0,0-6.84,6.84v80.64h-26V405.18A31.49,31.49,0,0,1,545.11,381.93Z" transform="translate(-535.47 -372.29)"/><path d="M790,398.18H700.86V416H790v26H700.86V453.7a6.15,6.15,0,0,0,2,4.43,6.63,6.63,0,0,0,4.9,1.71H790v25.82H707.78q-13.21,0-23.25-10A32.15,32.15,0,0,1,675,452v-79.7H790Z" transform="translate(-535.47 -372.29)"/><path d="M805.52,406a32.08,32.08,0,0,1,9.57-23.64q9.95-10,23.24-10h91.76V452a32.12,32.12,0,0,1-9.57,23.64q-9.94,10-23.25,10H838.33q-13.29,0-23.24-10A32.12,32.12,0,0,1,805.52,452Zm25.89,47.74a6.35,6.35,0,0,0,2,4.43,6.94,6.94,0,0,0,4.89,1.71h7.94v-.08h43.07v.08h7.93a7,7,0,0,0,4.9-1.71,6.34,6.34,0,0,0,2-4.43V398.18H846.27v-.08h-7.94a6.94,6.94,0,0,0-4.89,1.71,6.35,6.35,0,0,0-2,4.43Z" transform="translate(-535.47 -372.29)"/><path d="M1067.1,372.29v25.89h-47v87.48H994.24V398.18h-48.6V372.29Z" transform="translate(-535.47 -372.29)"/><path d="M1197.65,398.18h-89.11V416h89.11v26h-89.11V453.7a6.15,6.15,0,0,0,2,4.43,6.63,6.63,0,0,0,4.9,1.71h82.19v25.82h-82.19q-13.21,0-23.25-10a32.11,32.11,0,0,1-9.56-23.64v-79.7h115Z" transform="translate(-535.47 -372.29)"/><path d="M1348.19,372.29,1303.4,429l44.79,56.69h-32l-35.54-45-35.53,45h-32L1258,429l-44.79-56.68h32l35.53,44.94,35.54-44.94Z" transform="translate(-535.47 -372.29)"/></svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    #navbar {
        z-index: 1000;
        background-color: none;
        mix-blend-mode: difference;
    }
    #navbar .nav-brand {
        
        margin-top: 1px;
        pointer-events: none;
    }

    #navbar .nav-logo {
        height: 12px;
        fill: #fff;
    }
</style>